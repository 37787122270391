module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Thulium.com","short_name":"Thulium.com","start_url":"/","background_color":"#E1E1E1","theme_color":"hsl(356, 56%, 53%)","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a09ec6a30919afff7a0e556ea4126eb3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["pl","en","de"],"defaultLanguage":"pl","generateDefaultLanguagePage":true,"trailingSlash":"never","redirect":true,"siteUrl":"https://thulium.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"transSupportBasicHtmlNodes":true},"pages":[{"matchPath":"/partners","languages":["pl"]},{"matchPath":"/ebooks","languages":["pl"]},{"matchPath":"/:lang?/product/:name","getLanguageFromPath":true},{"matchPath":"/:lang?/industries/:name","getLanguageFromPath":true},{"matchPath":"/:lang?/casestudies/:name","getLanguageFromPath":true},{"matchPath":"/:lang?/terms-and-conditions/:name","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/:name","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/:category/:page","getLanguageFromPath":true},{"matchPath":"/:lang?/partnership-:name","getLanguageFromPath":true},{"matchPath":"/:lang?/landingpage-:name","getLanguageFromPath":true},{"matchPath":"/:lang?/blog","getLanguageFromPath":true},{"matchPath":"/pricing/call-and-sms","languages":["pl"]},{"matchPath":"/dsa","languages":["pl"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T4X5C98","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
